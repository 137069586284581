<div class="app-container">
  <div class="app-header" *ngIf="userIsLoggedIn">
    <div class="app-header-inner">
      <div *ngIf="!canViewSuper">👋</div>
      <ul nz-menu nzMode="horizontal" *ngIf="canViewSuper">
        <li nz-menu-item nzMatchRouter nzMatchRouterExact>
          <a [routerLink]="['/']">Home</a>
        </li>
      </ul>
      <div class="right">
        <button nz-button nzType="text" nzSize="small" (click)="logout()">
          Log out
        </button>
      </div>
    </div>
  </div>
  <div class="app-content">
    <router-outlet *ngIf="canViewSuper"></router-outlet>
    <nz-card
      class="login"
      *ngIf="!userIsLoggedIn"
      [nzBodyStyle]="{ padding: '0px' }"
    >
      <div class="login-inner">
        <nz-spin *ngIf="isLoadingUser" [nzSpinning]="true" nzSimple> </nz-spin>
        <h1 *ngIf="!isLoadingUser">👋🪐🤳</h1>
        <button
          nz-button
          (click)="attemptLogin()"
          nzBlock
          nzSize="large"
          nzType="primary"
          *ngIf="!isLoadingUser"
        >
          Log in
        </button>
      </div>
    </nz-card>
  </div>
</div>
